import React from "react"
import LottieInView from "./LottieInView"
import Anim from "./404 Page.json"

const FourOhFour = () => (
  <LottieInView
    animationData={Anim}
    id="PAKD 404 Lottie"
    speed={1}
    width="100%"
    height="100%"
    threshold={1}
    inViewTimeout={250}
  />
)

export default FourOhFour

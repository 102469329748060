import React from "react"
import { Container, Row } from "react-grid-system"
import SEO from "../components/seo"
import FourOhFour from "../components/lottie/404"
import { Header1, Button } from "../elements"
import { rhythm } from "../../config/typography"
import TransitionContext from "../context/TransitionContext"

const NotFoundPage = props => (
  <>
    <SEO title="404: Not found" pathname={props.location.pathname} />
    <TransitionContext location={props.location.pathname}>
      <Container fluid className="pagemargintop pagemarginbtm innerwrap">
        <Row
          align="center"
          justify="center"
          style={{ transform: `translate3d(2.5%,0,0)` }}
        >
          <FourOhFour />
        </Row>
        <Header1
          lineOne="Welp, we messed up."
          lineTwo="This is embarassing..."
        />
        <Row
          align="center"
          justify="center"
          style={{ marginBottom: `${rhythm(2)}` }}
        >
          <Button secondary linkTo="/">
            Go Home
          </Button>
        </Row>
      </Container>
    </TransitionContext>
  </>
)

export default NotFoundPage
